.container {
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;

}
.header {
  width: 90%;
  margin: 1rem 2rem;
  padding: 2rem;
  background-color: fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.header p {
  color: var(--greyTop);
  font-size: medium;
}

.subcontainer {
  padding: 0rem 2rem;
}
.container h3 {
  color: var(--primaryColor);
}
.container h4 {
  color: var(--primaryColor);
  text-align: left;
}
.container span {
  font-style: italic;
  font-size: small;
}
.maintable {
  padding: 1rem 1rem;
  width: 100%;
}
.table {
  width: 100%;
  border: 1rem;
  color: var(--secondaryColor);
}

#table p:nth-child(even) {
  background-color: #f2f2f2;
}

#table p:hover {
  background-color: #ddd;
}

#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
.highlight {
  color: var(--greyCardTop);
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  margin-bottom: 2rem;
  text-align: justify;
  animation: bounce 1s ease-in-out;
}
.highlight:hover {
  color: var(--primaryColor);
  cursor: pointer;
}

.highlight p {
  color: var(--primaryColor);
  font-weight: bold;
}
.highlight p:hover {
  color: var(--lightBlue);
}
.button {
  width: 10%;
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  color: #f2f2f2;
  font-weight: bold;
  cursor: pointer;
  background-color: var(--lightBlue);
}
.button:hover {
  background-color: var(--primaryColor);
}

@media (max-width: 700px) {
  .container {
    padding: 0rem;
    text-align: justify;
    color: var(--secondaryColor);
  }
  .subcontainer {
    padding: 0rem !important;
  }
  .header {
    width: 85%;
    margin: 0.5rem;
    padding: 1rem 2rem;
  }
}
