.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.container li {
  display: inline-flex;
  padding-left: 2rem;
  cursor: pointer;
}

.column1 {
  width: 25%;
  padding: 0rem 2rem;
  height: max-content;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.column2 {
  width: 85%;
  justify-content: center;
  align-items: center;
}
.column3 {
  width: 25%;
  justify-content: center;
  align-items: center;
}
.column4 {
  padding: 0;
  justify-content: center;
  align-items: center;
}
.column5 {
  border-radius: 0.5rem;
  background-color: var(--lightBlue);
}

.column6 {
  margin-top: 1rem;
  width: 30%;
  border-radius: 0.5rem;
  background-color: var(--lightBlue);
}

.imageContainer {
  padding-left: 5rem;
  position: unset !important;
  width: 100% !important;
}

.image {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
  border-top-right-radius: 45px;
  border-bottom-left-radius: 45px;
  transition: transform 0.2s;
}
.column1 ul {
  padding-left: 50px;
}
.column1 li {
  color: var(--greyCardTop);
  font-size: small;
  list-style-type: none;
  line-height: 3rem;
  display: block;
}
.column1 li:hover {
  color: var(--primaryColor);
  font-weight: bold;
}
.column1 a {
  font-weight: bold;
  color: var(--primaryColor);
}
.column1 a:hover {
  color: yellowgreen;
}
.column1 h4 {
  text-align: center;
  background-color: var(--primaryColor);
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: #f2f2f2;
}

.column3 ul {
  padding-left: 50px;
}
.column3 li {
  color: var(--greyCardTop);
  font-size: small;
  list-style-type: none;
  line-height: 3rem;
  text-wrap: nowrap;
}
.column3 li:hover {
  color: var(--primaryColor);
  font-weight: bold;
}
.column3 a {
  font-weight: bold;
  color: var(--primaryColor);
}
.column3 a:hover {
  color: yellowgreen;
}

.rSideLinks li {
  font-size: 0.85rem !important;
  line-height: 2.25rem !important;
  list-style: none;
}

.column6 ul {
  padding-left: 50px;
}
.column6 li {
  color: #f2f2f2;
  font-size: small;
  list-style-type: none;
  line-height: 3rem;
  text-wrap: nowrap;
}
.column6 li:hover {
  color: var(--primaryColor);
  font-weight: bold;
}
.column6 a {
  font-weight: bold;
  color: var(--primaryColor);
}
.column6 a:hover {
  color: yellowgreen;
}
.column6 h4 {
  color: var(--primaryColor);
  padding-left: 1rem;
}
.competencies {
  margin-top: 1rem;
  padding-left: 0.5rem;
  padding-bottom: 1rem;
  background: var(--contactme);
  font-size: 0.85rem !important;
}
.competencylist li {
  display: inline-flex;
  line-height: 1.5rem !important;
  list-style: none;
}
.competencies li:hover {
  font-weight: bold;
}
.highlight {
  padding: 1.5rem;
  color: rgb(247, 245, 223);
}
.highlight h4 {
  color: rgb(248, 232, 8);
}
.button {
  width: 50%;
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  color: var(--greyCardTop);
  font-weight: bold;
  cursor: pointer;
  background-color: #f2f2f2;
}
.button:hover {
  background-color: rgb(248, 232, 11);
}

@media (max-width: 700px) {
  .column1 {
    display: none;
  }
  .column3 {
    display: none;
  }
  .column2 {
    width: 100%;
  }
  .column5 {
    display: none;
  }
  .column6 {
    display: none;
  }
}
