.container {
  margin: 0;
  padding: 0;
  width: 100%;
}
.bodyshowcase {
  padding: 3rem;
  text-align: center;
  line-height: 2.5rem;
  color: var(--greyTop);
}

.bodyshowcase img {
  animation: swing 10s infinite;
}

@keyframes swing {
  50% {
    transform: rotate(180deg);
  }
}
@media (max-width: 700px) {
  .container {
    width: fit-content
  }
}
