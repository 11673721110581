.mainContainer {
  margin: 0;
  padding: 0.5em 5em;
}

.mainContainer h1 {
  text-align: center;
  color: var(--secondaryColor);
}

.mainContainer p {
  color: var(--greyTop);
}
.header {
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  width: 95%;
  padding: 1.5em;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 1em;
}
.header img {
  width: 50%;
  border-radius: 8px;
  cursor: pointer;
}

.header span {
  font-weight: bold;
 }
.imgProject img{
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  background-color: brown;
}

@media (max-width: 700px) {
  .mainContainer {
    margin: 0;
    padding: 0.5em 0.85em;
  }
}
