/* Slider.css */
.slider-container {
  
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.slideImage{
  text-align: center;
 
}
.slide {
  padding: 1rem;
  position:absolute;
  opacity: 0;
  
  margin-bottom: 2rem;
}

.slide.active {
  padding: 1rem;
  position:relative;
  opacity: 1;
}

.slide img {
  width: 40%;
  height: auto;
}

.indicator-bar {
  margin-top: 15rem;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.indicator {

  width: 20px;
  height: 20px;
  background-color: #f0e3e3;
  border-radius: 50%;
  margin: 10px 5px;
  cursor: pointer;
  z-index: 1;
}

.indicator.active {
  background-color: red
}
