/* NavBar.css */
.navbar {
  display: flex;
  align-items: center;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: -5px;
  z-index: 2;
  height: 50px;
  min-height: 50px;
  width: calc(100% - 1.5rem);
  background-image: url("johnmakola.jpg");
  padding: 0 1rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);
}

.menu-icon {
  font-size: 1.5rem;
  cursor: pointer;
  display: none;
  color: #fff;
}
.logoDisplayMinimized {
  display: none;
}


.nav-links {
  width: 70%;
  font-size: small;
  margin-left: 1rem;
  list-style: none;
  display: flex;
  white-space: nowrap;
}

.nav-links li {
  margin-right: 1rem;
  padding-left: 1rem;
  position: relative;
  list-style: none;
  line-height: 2rem;
}

.nav-links a {
  text-decoration: none;
  color: #fff;
}
.nav-links a:hover {
  text-decoration: none;
  color: #fff;
}

.sub-links {
  width: max-content;
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  background-color: var(--primaryColor);
  z-index: 1;
  padding: 0;
}

.sub-links li {
  width: 88%;
  border-bottom: 0.045rem solid #fff;
}
.sub-links a {
  padding: 0.5rem;
  color: #fff;
}
.sub-links a:hover {
  color: var(--greyCardTop)
}
.sub-links li:hover {
  background-color: var(--secondaryColor);
  padding: 0%;
  width: 100%;
 
}

.nav-links li:hover .sub-links {
  display: block;
}

.nonlinks {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar input {
  margin-left: 0;
  width: 75%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
}
.navbar input:focus {
  background-color: var(--secondaryColor);
}
.navbar p {
  padding: 0rem 0.5rem;
  color: #fff;
  cursor: pointer;
}

.TitleLink {
  display: flex;
  margin-top: 0.2rem;
}

@media screen and (max-width: 768px) {

  .menu-icon {
    display: block;
  }
  .logoDisplayMinimized {
    color: #fff;
    padding: 2rem 1rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-wrap: nowrap;
    display: flex !important;
    flex-direction: row !important;
  }
  .logoDisplayMinimized img {
    padding: 2rem;
    width:20%;
    margin-right: 1rem;
  }
  
  .titleHidden {
    display: none;
  }
  .nav-links {
    display: flex;
    margin-left: 0;
    padding: 0;
    flex-direction: column;
    width: 50%;
    position: absolute;
    top: 25px;
    background-color: var(--primaryColor);
    display: none;
    z-index: 1;
  }
  .nav-links li {
    margin: 0;
    padding: 0.5rem;
    line-height: 1rem;
    border-bottom: 0.02rem solid #fff;
    text-wrap: nowrap;
  }
  .nav-links li:hover {
    padding: 0.5rem;
    background-color: var(--secondaryColor);
    cursor: pointer;
  }

  .nav-links a:hover {
    color: #000;
  }

  .sub-links {
    width: 150px;
    position: absolute;
    left: 7rem;
    margin-top: -2rem;
    background-color: var(--primaryColor);
    z-index: 1;
    padding: 0;
  }

  .sub-links li {
    line-height: 1rem;
  }
  .sub-links li:hover {
    width: 90%;
  }
  .sub-links a:hover {
    color: #fff;
  }

  .nav-links.active {
    display: flex;
  }
  .sub-links a {
    padding: 0;
  }
  .socialLinks {
    margin-top: -10rem;
    width: 70%;
    float: right !important;
    margin-right: 10px;
  }
}
