.main {
  margin-top: -4rem;
  padding: 0;
  display: flex;
  height: 300px;
  margin-bottom: 4rem;
  
}
.productsContainer {
  margin: 0;
  padding: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  justify-content: center !important;
 
}
.productCard {
  overflow: hidden;
}
.productCard img {
  width: fit-content;
  object-fit: cover;
}
.subContainer {
  padding: 0.5rem 2rem;
  background-color: var(--product);
  height: max-content;
}
.subContainerB {
  width: 50%;
}
.subContainer h1 {
  text-align: center;
  color: var(--greyCardTop);
}
.subContainer h1:hover {
  transform: translateY(-5px);
  color: var(--blendGreenColor);
  cursor: pointer;
}

.subContainer li {
  padding: 0 2rem;
  line-height: 3.5rem;
  color: var(--greyTop);
  list-style: none;
}
.subContainer li:hover {
  padding: 0rem;
  color: var(--secondaryColor);
  font-weight: bold;
  transform: translateY(-5px);
  border-bottom: solid 0.0025rem var(--greyTop);
}
.subContainer p {
  padding: 0 2rem;
  color: var(--greyCardTop);
}
.subContainer h3 {
  padding: 0 2rem;
  color: var(--greyCardTop);
}
.infobtn {
  margin: 1rem 0.8rem;
  float: right;
  display: block; /* Ensure button takes full width */
  background-color: var(--primaryColor);
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* Add margin for spacing */
}

.infobtn:hover {
  background-color: var(--techStackBar);
}

.bodyshowcase {
  padding: 3rem;
  text-align: center;
  line-height: 2.5rem;
  color: var(--greyTop);
}

.bodyshowcase img {
  width: 80%;
}

@keyframes swing {
  50% {
    transform: rotate(180deg);
  }
}

@media (max-width: 700px) {
  .main {
    display: block;
  }
  .subContainer {
    padding: 0.5rem;
    width: 100%;
  }
  .subContainerB {
    width: 100%;
  }
  .productsContainer {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
