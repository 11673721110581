
.themesContainer {  
  width:100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  gap: 0.5rem;
}

.productCard {
  font-size: small;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
}

.productCard:hover {
  transform: translateY(-5px);
  cursor: pointer;
}

.productCard img {
  width: 100%;
  object-fit: cover;
}

@media (max-width: 1240px) {
  .themesContainer {
    float: right;
  }

}

@media (max-width: 700px) {
  .themesContainer {
    float: right;
    width:70%;
    margin-right:0rem;
    margin-top: 10.5rem;
    gap: 0.5rem;
    
  }

}
