.main {
  margin: 0;
  display: none;
}

.themesContainer {
  margin: 2.5rem;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  gap: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.productCard {
  background-color: #ffffff;
  border-radius: 8px;
  font-size: small;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
}

.productCard:hover {
  cursor: pointer;
  transform: translateY(-10px);
  border: solid 0.1rem var(--lightBlue);
}

.productCard img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 2rem;
}

.productCard h5 {
  padding: 0.5rem;
  margin: 0;
  color: var(--primaryColor);
  font-size: 18px;
  text-align: center;
  line-height: 1.5rem;
}
.productCard p {
  line-height: 1.5rem;
  padding: 0rem 0.8rem;
  font-size: small;
  font-style: italic;
}
.productInfo {
  margin-top: 12.5rem;
  text-align: center;
  color: var(--greyCardTop);
  line-height: 0.025rem;
}

.addcart {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.button {
  margin: 0rem 0.8rem;
  display: block; /* Ensure button takes full width */
  background-color: var(--primaryColor);
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* Add margin for spacing */
}

.button:hover {
  background-color: var(--techStackBar);
}

.favoriteContainer {
  position: relative;
  display: flex;
}
.favouriteImage {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.favorite {
  z-index: 9;
  margin: 0.5rem 12.5rem;
  padding: 0rem 2rem;
}

.loveHearts {
  color: var(--blendGreenColor);
  background-color: #f5f9fc;
}
.loveHearts:hover {
  cursor: pointer;
}

@media (max-width: 1240px) {
  .themesContainer {
    padding: 1rem;
  }

  .productInfo {
    font-size: medium;
    padding: 0.5rem;
    text-wrap: wrap;
    line-height: 1.5rem;
  }
  .addcart {
    display: block;
    text-align: center;
    padding: 0.2rem;
  }
  .addcartSection {
    display: flex;
  }
}

@media (max-width: 700px) {
  .themesContainer {
    grid-template-columns: repeat(auto-fill, minmax(103%, 1fr));
    gap: 10px;
    padding: 1rem;
    margin: 0.5rem 0.5rem;
  }
  .product-container {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    gap: 10px;
    padding: 3rem 0.5rem;
  }
  .productInfo {
    margin-top: 12rem;
    line-height: 1.5rem;
    text-wrap: wrap;
    text-align: center;
  }
  .productCard {
    width: 100%;
  }
  .productCard h5 {
    padding: 6px;
  }
  .productCard p {
    font-size: small;
  }
  .favorite {
    margin: 0.5rem 15.5rem;
    padding: 0rem 1rem;
  }
  .addcart {
    margin-top: -1rem;
    display: block;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .addcartSection {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
