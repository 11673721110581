.mainContainer {
  margin: 0 auto;
  background-color: #f6f9fc !important;
  color: #f6f9fc;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-image: url("johnmakola.jpg");
}
.category-container {
  padding: 1rem;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  font-size: smaller;
  text-align: center;
}

.category-card {
  overflow: hidden;
}

.category-card h3 {
  padding: 0.2rem 0.75rem;
  margin: 0;

  font-size: 18px;
  text-align: left;
}

.category-card li {
  list-style: none;
  padding-left: 1rem;
  line-height: 2rem;
}
.category-card a {
  color: var(--greyCardTop);
}
.category-card a:hover {
  color: #000;
  font-weight: bold;
}
@media (max-width: 700px) {
  .category-container {
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    gap: 10px;
  }
}
