:root {
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

    --border-radius: 12px;
    --primaryColor: #0a1930;
    --secondaryColor: #013332;
    --backgroundColor: #d7e2f4;
    --hoverColor: #cfd13e;
    --cardColor: #d4d4d4;
    --technologyStack: #dee8f7;
    --techStackBar: #58e0c0;
    --contactme: #f2f3f3;
    --greyTop:#727376;
}
body {
  margin: 0;
  overflow-x: hidden;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  color: #d7e3f5;
  text-decoration: none;
}
